import './style.scss'
import {getCookie} from '../../../js/utils/cookies'
import UiModal from '../../../js/components/UiModal/UiModal'
import { showOrderModal } from '../../popups/v1/OrderModal/scripts'

export default !function () {
  const blocks = document.querySelectorAll('.universal-info')

  if(!blocks.length) {
    return
  }

  const modalNameToOptions = {
    'sale-modal': {
      hiddenFields: [{ name: 'type', value: 'event' }]
    },
    'order-modal': {},
    'special-modal': {
      hiddenFields: [{ name: 'special_block', value: 'spo' }]
    }
  }

  blocks.forEach((block) => {
    const blockName = block.closest('[data-block-name]')?.dataset.blockName || 'Блок без названия'
    const button = block.querySelector('.universal-info__button')
    const learnMoreButton = block.querySelector('.universal-info__more')

    if (button) {
      button.addEventListener('click', () => {
        const options = modalNameToOptions[button.dataset.actionButton]

        if (options) {
          window.$analytics?.push('info-subscription-click', { blockName });

          showOrderModal({
            ...options,
            submitHandler: () => window.$analytics?.push('info-submit', { blockName }),
            blockName
          })
        }

        // Аналитика кнопок ведущих к заявке (new-analytics)
        const buttonType = button.dataset.actionButton || (button.dataset.redirectLink && 'redirect')

        if (buttonType) {
          window.$analytics?.push('all-block-consult-button-click', {
            blockName: blockName,
            buttonType,
            buttonText: button.textContent
          })
        }
      })

      if (button.dataset.redirectLink) {
        const utmFromCookie = getCookie('utm_aggregated_data')

        if (utmFromCookie) {
          button.href = `${button.href}?${utmFromCookie.replace(/\|\|\|/g, '&')}`
        }
      }
    }

    // learn more button
    const detailsTemplate = block.querySelector('#universal-info-details')

    learnMoreButton?.addEventListener('click', (evt) => {
      if (detailsTemplate && learnMoreButton.closest("[href='']")) {
        evt.preventDefault()
        const detailsElement = detailsTemplate.content.querySelector('.universal-info-details__wrapper')
        new UiModal(detailsElement, { wrapperClass: 'universal-info-details' }).init()
      }

      window.$analytics?.push('all-block-learn-more-click', {
        blockName
      })
    })
  })
}()
